import PropTypes from 'prop-types'
import { format } from 'timeago.js'
import dayjs from 'dayjs'

/**
 * Format `datetime` in the "*** time ago" format, eg: '3 hours ago'
 * @param datetime
 * @param locale
 * @param children Fallback to display if `cutoff` seconds have passed since `datetime`
 * @param cutoff   Defaults to no cutoff, i.e. always use the  "*** time ago" format
 * @returns
 */
export const TimeAgo = ({ datetime, locale = 'en', children, cutoff }) => {
  const secondsAgo = (new Date() - new Date(datetime)) / 1000

  return (
    <time timeago={datetime}>
      {secondsAgo > cutoff ? children : format(datetime, locale)}
    </time>
  )
}

export const isMoreThan24Hours = (created) =>
  dayjs().diff(dayjs.unix(created), 'hours') > 24

TimeAgo.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.node,
  cutoff: PropTypes.number,
  created: PropTypes.string,
  datetime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
}
