import styled from 'styled-components'
import { ArticleData, FC } from '~/types'
import SocialLinks from '~/section/SocialLinks'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Title = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  text-align: left;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 0;
`
const Bio = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.fontColor};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-style: italic;
`

const Website = styled.a`
  color: ${({ theme }) => theme.fontColor};
  font-weight: 700;
  text-decoration: underline ${({ theme }) => theme.primaryColor};
  text-decoration-thickness: 2px;
  text-decoration-skip-ink: auto;
  text-underline-offset: 3px;
  font-style: italic;
  margin: 10px 0;
`

type AboutPartnerProps = {
  article: ArticleData
}

const AboutPartner: FC<AboutPartnerProps> = ({ article }) => {
  if (!article.partners?.length) return null
  const { name, extra } = article.partners[0]
  if (!extra) return null
  const { website, bio } = extra

  return (
    <Wrapper>
      <Title>
        About our Partner <em>{name}</em>
      </Title>
      {bio && <Bio>{bio}</Bio>}
      {website && (
        <span>
          Visit {name}'s website from{' '}
          <Website href={website} target="_blank" rel="noopener noreferrer">
            here.
          </Website>
        </span>
      )}
      <Title>Follow on social media:</Title>
      {extra && <SocialLinks extra={extra} />}
    </Wrapper>
  )
}

export default AboutPartner
