import React from 'react'
import generateEditedLead from '~/section/utils/generate-edited-lead'
import { isCompWithLead } from '~/section/utils/show-lead'
import { FC, SectionComponentType } from '~/types'

type LeadProps = {
  type?: SectionComponentType
  isFeaturedx4?: boolean
  isCard?: boolean
  includeStandfirst: boolean
  lead: string
  isLeadEnabled?: boolean
  path: string
  isRemotePublication?: boolean
}

const Lead: FC<LeadProps> = ({
  type,
  isFeaturedx4,
  lead,
  isLeadEnabled = true,
  path,
  includeStandfirst,
  isCard,
  isRemotePublication,
}) => {
  if (
    !isLeadEnabled ||
    !lead ||
    !type ||
    isCard ||
    !isCompWithLead(includeStandfirst, type, isFeaturedx4, path)
  )
    return null

  const editedLead = generateEditedLead(lead, includeStandfirst, type)

  return (
    <div className="standfirst-wrapper">
      <a
        href={path}
        className="standfirst-link"
        // For external links (ie. 3AM)…
        {...(isRemotePublication && {
          target: '_blank',
          rel: 'noopener noreferrer',
        })}
      >
        <p className="standfirst">{editedLead}</p>
      </a>
    </div>
  )
}

export default Lead
