import * as Throttle from 'promise-parallel-throttle'
import { renderIslands } from '@brightsites/island-hydration'
import { HeroGrid } from '~/library/Article/HeroGrid'
import { smoothScroll } from '~/library/Article/smoothScroll'
import { mobileSubmenuListener } from '~/library/Article/mobile-submenu-listener'
import { archiveAccordion } from '~/util/archiveAccordion'
import articleNextPrev from '~/util/articleNextPrev'
import { initAudienceProject } from '~/util/audienceProject'
import carousel from '~/util/carousel'
import clickTracking from '~/util/clickTracking'
import { initChartbeat } from '~/util/initChartbeat'
import { updatePublishedDateAgo } from '~/util/date-ago'
// import commentCountWording from '~/util/commentCount'
import { facebookSDK } from '~/util/facebookSDK'
import polyfill from '~/js/polyfill'
import reportWebVitals from '~/util/reportWebVitals'
import { initIpsos } from '~/util/lpsos'
import { instagramEmbed } from '~/util/get-external-scripts'
import { moreLess } from '~/util/moreLess'
import { initGoogleSearchSelector } from '~/util/search-selector'
import {
  fontawesomeScript,
  hubspotFormsScript,
  hubspotScript,
  newPolarNativeScript,
  newsletterPage,
  skimlinksScript,
} from '~/util/get-external-scripts'
import hideWeatherWidgetOnScroll from '~/util/hideWeatherWidgetOnScroll'
import { trackArticleContentScroll } from '~/util/trackArticleContentScroll'
import { hubspotForm } from '~/util/hubspot-form'
import { imgFallback } from '~/util/imgFallback'
import { group, groupEnd } from '~/util/log'
import delayMenuHoverState from '~/util/menu'
import { initOneSignal } from '~/util/oneSignal'
import { optaScript } from '~/util/opta'
import { domainsWithCustomCTAHeader } from '~/config/JPIMConfig'
import { parselyProfileScript } from '~/util/parselyProfile'
import peopleBioMobile from '~/util/peopleBioMobile'
import accordion from '~/util/quizAccordion'
import { resizeStickySidebar } from '~/util/resize-sticky-sidebar'
import { shareArticle } from '~/util/shareArticle'
import { socialShareModal } from '~/util/social-share-modal'
import switchGeoLocation from '~/util/switchGeolocation'
import { toggleDetailedMenu } from '~/util/toggle-detailed-menu'
import { toggleTabbedContent } from '~/util/toggle-tabbed-content'
import * as timer from '~/util/time-performance'
import { twitterWidget } from '~/util/twitterWidget'
import upNext from '~/util/upNext'
import { initVideohub } from '~/util/videohub'
import { LiveblogHydrated } from './components/LiveblogHydrated/LiveblogHydrated'
import { BreakingNewsTicker } from './components/BreakingNewsTicker'
import InfiniteScroll from './components/InfiniteScroll'
import Taboola from './components/Taboola'
import { TodaysDate } from './components/TodaysDate'
import { Twitter } from './components/Twitter'
import { WeatherWidget } from './components/WeatherWidget'
import ThemeProvider from './providers/ThemeProvider'
import adPartnerCarousel from '~/util/adPartnerCarousel'
import disqusCommentCountWording from '~/util/disqusCommentCount'
import vfCommentCountWording from '~/util/vfCommentCount'
import { continueReading } from '~/util/continueReading'
import { toggleHeroCaption } from '~/util/toggleHeroCaption'
import { customCTAHeaderActions } from '~/util/customCTAHeaderActions'
import { injectTaboolaFlushScript } from '~/util/taboola'
import { newsletterSignupForm } from '~/library/NewsletterSignup/utils/newsletter-signup-form'

// Check if window.JSGlobals.pageType exists and pass it to initialisePreConsent
const pageType = window?.JSGlobals?.pageType ?? ''

// Determine if the pageType should trigger Taboola scripts
const isTaboolaPage =
  pageType === 'article' ||
  pageType === 'video article' ||
  pageType === 'live blog article' ||
  pageType === 'photo article'

window.JSGlobals = window.JSGlobals ?? {
  mode: 'development',
}

// Scroll to top when user navigates back to an infinite scroll article
if (
  window.JSGlobals &&
  window.JSGlobals.pageType === 'article' &&
  window.JSGlobals.isInfiniteScrollArticle
) {
  if (history.scrollRestoration) {
    history.scrollRestoration = 'manual'
  } else {
    window.onbeforeunload = function () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}

const isDisqusSite = window.JSGlobals.domain === 'scotsman.com'
const hasCustomCTAHeader = domainsWithCustomCTAHeader.includes(
  window.JSGlobals.domain,
)

// Define script load order here:
const initialisers = [
  // First group - Critical for ads and page functionality
  {
    initVideohub: async () => {
      await initVideohub() // nationalworldtv video player
    },
    initAudienceProject, // Audience Project surveys
  },
  // Second group - Important but not blocking
  {
    skimlinksScript, // Skimlinks affiliate links
    newPolarNativeScript, // Polar script for sponsored pages
  },
  // Third group - Moved from pre-consent, lower priority
  {
    polyfill,
    webvitals: reportWebVitals,
    ipsos: initIpsos, // Ipsos audience measurement
    instagram: instagramEmbed, // Instagram embed
    moreless: moreLess, // Loaded here as a workaround for Safari script loading issue (https://jpimediapublishing.atlassian.net/browse/EWB-493)
    googlesearch: initGoogleSearchSelector, // Loaded here so that it's ready if search is clicked / DetailedMenu opened immediately on page load
  },
  {
    optaScript, // Sports widgets
    initOneSignal, // OneSignal push notifications
    hubspotScript, // Hubspot tracking
    hubspotFormsScript, // Hubspot forms
    newsletterPage,
    fontawesomeScript, // Jobs page assets
  },
  {
    initChartbeat, // Chartbeat tracking
  },
  {
    newsletterSignupForm,
    adPartnerCarousel,
    continueReading,
    ...(hasCustomCTAHeader && { customCTAHeaderActions }),
    toggleHeroCaption,
    parselyProfileScript, // Parsely personalized article recommendation
    resizeStickySidebar,
    clickTracking,
    articleNextPrev,
    upNext,
    delayMenuHoverState,
    accordion,
    hideWeatherWidgetOnScroll,
    trackArticleContentScroll,
    peopleBioMobile,
    toggleDetailedMenu,
    toggleTabbedContent,
    socialShareModal,
    // commentCountWording,
    ...(isDisqusSite
      ? { disqusCommentCountWording }
      : { vfCommentCountWording }),
    switchGeoLocation,
    carousel,
    updatePublishedDateAgo,
    imgFallback,
    archiveAccordion,
    handleIslands: () => {
      renderIslands(
        {
          BreakingNewsTicker,
          InfiniteScroll,
          LiveblogHydrated,
          Taboola,
          TodaysDate,
          Twitter,
          WeatherWidget,
          HeroGrid,
        },
        [ThemeProvider],
      )
    },
    facebookSDK,
    twitterWidget,
    shareArticle,
    hubspotForm,
    mobileSubmenuListener,
    smoothScroll,
    // Inject the Taboola flush script if necessary
    ...(isTaboolaPage && [
      {
        method: () => {
          injectTaboolaFlushScript() // Inject the flush script
        },
      },
    ]),
  },
]

// @todo define types for this to move file to TypeScript
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const initPromises = (inits) =>
  Object.keys(inits).map((key) => async () => {
    const initFunc = inits[key]
    if (!initFunc) {
      return
    }
    timer.start(key)
    try {
      if (initFunc.method) {
        await initFunc.method()
        timer.end(key)
        if (initFunc.dependents) {
          const groupName = `${key} deps`
          group(groupName)
          await Throttle.all(initPromises(initFunc.dependents))
          groupEnd()
        }
      } else {
        await initFunc()
        timer.end(key)
      }
    } catch (e) {
      console.warn(`Error caught in ${key}()`)
      console.error(e)
    }
  })

const initialiseModules = async () => {
  for (let i = 0; i < initialisers.length; i++) {
    const groupName = `group ${i + 1}`
    group(groupName)
    await Throttle.all(initPromises(initialisers[i]))
    groupEnd()
  }
}

initialiseModules()
