export const handleClickTracking = (
  event: string,
  titleShort: string,
  url: string,
  index: number,
) => {
  const strippedUrl = url.split('?')[0] as string
  // casting because noUncheckedIndexAccess warns that the first item of the array could be undefined

  const data = {
    clickText: titleShort,
    clickUrl: strippedUrl,
    contentId: extractIdFromUrl(strippedUrl),
    elementPosition: index,
    event,
    position: 'header',
    selectionName: event,
  }
  window.dataLayer?.push(data)
}

const extractIdFromUrl = (url: string): string => {
  const regex = /(\d+)$/
  const match = regex.exec(url)
  return match ? match[0] : ''
}
