import { openDB, IDBPDatabase } from 'idb'
import { ArticleData } from '~/types'

interface CacheEntry<T> {
  data: T
  timestamp: number
}

interface CacheResult<T> {
  data: T | null
  isStale: boolean
  timestamp: number
}

const ONE_MINUTE = 60 * 1000

/**
 * Creates a cache manager instance that provides a wrapper around IndexedDB for client-side caching.
 * Includes features like automatic cache invalidation and cross-tab synchronization.
 *
 * @template T - The type of data to be cached
 * @param {string} storeName - Unique identifier for the IndexedDB store
 * @param {number} [duration=60000] - Cache validity duration in milliseconds
 *
 * @returns {Object} Cache manager instance with the following methods:
 * - set(key: string, data: T): Promise<void> - Stores data in cache
 * - get(key: string): Promise<CacheResult<T>> - Retrieves cached data with metadata
 * - subscribeToUpdates(callback: (data: T) => void): () => void - Subscribe to cross-tab updates
 *
 * @example
 * // Create a cache instance
 * const myCache = createCacheManager<MyDataType>('store-name', 5 * 60 * 1000) // 5 minutes
 *
 * // Store data
 * await myCache.set('my-key', myData)
 *
 * // Retrieve data
 * const result = await myCache.get('my-key')
 * if (!result.isStale && result.data) {
 *   // Use fresh cached data
 * }
 *
 * // Subscribe to updates across tabs
 * const unsubscribe = myCache.subscribeToUpdates((newData) => {
 *   // Handle updated data
 * })
 *
 * @throws {Error} When IndexedDB is not supported in the environment
 * @requires window.indexedDB
 * @requires BroadcastChannel API
 */
export function createCacheManager<T>(
  storeName: string,
  duration = ONE_MINUTE,
) {
  let db: Promise<IDBPDatabase> | null = null

  // Only initialize IndexedDB in browser environment
  if (typeof window !== 'undefined') {
    db = initDB()
  }

  function initDB() {
    if (!('indexedDB' in window)) {
      console.error('IndexedDB is not supported in this environment')
      return null
    }

    return openDB(storeName, 1, {
      upgrade(db) {
        db.createObjectStore('cache')
      },
    })
  }

  function broadcastUpdate(key: string, entry: CacheEntry<T>) {
    if (typeof window === 'undefined') return

    const channel = new BroadcastChannel(storeName)
    channel.postMessage({ key, entry })
  }

  async function set(key: string, data: T): Promise<void> {
    if (!db) return

    const entry: CacheEntry<T> = {
      data,
      timestamp: Date.now(),
    }
    const dbInstance = await db
    await dbInstance.put('cache', entry, key)
    broadcastUpdate(key, entry)
  }

  async function get(key: string): Promise<CacheResult<T>> {
    if (!db) {
      return { data: null, isStale: true, timestamp: 0 }
    }

    const dbInstance = await db
    const entry = (await dbInstance.get('cache', key)) as
      | CacheEntry<T>
      | undefined

    if (!entry) {
      return { data: null, isStale: true, timestamp: 0 }
    }

    const isStale = Date.now() - entry.timestamp > duration
    return {
      data: entry.data,
      isStale,
      timestamp: entry.timestamp,
    }
  }

  function subscribeToUpdates(callback: (data: T) => void): () => void {
    if (typeof window === 'undefined') {
      return () => {}
    }

    const channel = new BroadcastChannel(storeName)

    const handler = (event: MessageEvent) => {
      const { entry } = event.data
      callback(entry.data)
    }

    channel.addEventListener('message', handler)
    return () => channel.removeEventListener('message', handler)
  }

  return {
    set,
    get,
    subscribeToUpdates,
  }
}

// breaking news cache
export const breakingNewsCache =
  createCacheManager<ArticleData[]>('breaking_news')
