import React, { useEffect, useRef, useState } from 'react'
import { Configuration, Domain, FC, ArticleData } from '~/types'
import { Loading } from './Loading'
import { TickerList } from './TickerList'
import { Breaking, Inner, TickerWrapper, Wrapper } from './styles'
import { breakingNewsCache } from '~/util/cache-manager'

const WRAPPER_CLEAR_SPACE_LEFT = 84

type BreakingNewsTickerProps = {
  domain: Domain
  configuration: Configuration
  path: string
  pageType: string
}

export const BreakingNewsTicker: FC<BreakingNewsTickerProps> = ({
  domain,
  configuration,
  path,
  pageType,
}) => {
  const [breakingNews, setBreakingNews] = useState<ArticleData[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isAnimating, setIsAnimating] = useState(false)
  const [animationDuration, setAnimationDuration] = useState(0)
  const newsWrapperRef = useRef<HTMLDivElement>(null)
  const newsTickerRef = useRef<HTMLDivElement>(null)
  const breakingNewsId =
    configuration.publicationConfig?.breakingNewsCarouselList

  // Get tracking term
  const trackingTerm = (() => {
    if (path === '/') {
      return 'homepage'
    }

    return pageType
  })()

  // Get data
  useEffect(() => {
    const getBreakingNews = async () => {
      if (!breakingNewsId) return

      // Get cached data first
      const { data: cached, isStale } = await breakingNewsCache.get(
        breakingNewsId.toString(),
      )

      if (cached && !isStale) {
        setBreakingNews(cached)
        setIsLoading(false)
        return
      }

      try {
        const response = await fetch(`/api/breaking-news?id=${breakingNewsId}`)
        const { articles }: { articles: ArticleData[] } = await response.json()
        setBreakingNews(articles)

        await breakingNewsCache.set(breakingNewsId.toString(), articles)
      } catch (error) {
        console.error('Failed to fetch breaking news:', error)
      } finally {
        setIsLoading(false)
      }
    }

    getBreakingNews()

    // Subscribe to updates from other tabs
    const unsubscribe = breakingNewsCache.subscribeToUpdates(
      (articles: ArticleData[]) => {
        setBreakingNews(articles)
        setIsLoading(false)
      },
    )

    return () => unsubscribe()
  }, [breakingNewsId])

  // Animate ticker when loaded
  useEffect(() => {
    if (isLoading || !breakingNewsId) return
    const newsWrapper = newsWrapperRef.current
    const newsTicker = newsTickerRef.current
    ;(() => {
      const tickerWidth = newsTicker?.offsetWidth || 0
      const wrapperWidth = newsWrapper?.offsetWidth || 0
      if (tickerWidth > wrapperWidth - WRAPPER_CLEAR_SPACE_LEFT) {
        const duration = Math.ceil(tickerWidth * 0.03)
        setAnimationDuration(duration)
        setIsAnimating(true)
      }
    })()
  }, [isLoading])

  return (
    <Wrapper data-testid="bnews-wrapper">
      <Inner
        ref={newsWrapperRef}
        domain={domain}
        data-testid="bnews-inner-wrapper"
      >
        <Breaking data-testid="bnews-text">BREAKING</Breaking>
        {isLoading ? (
          <Loading />
        ) : (
          <TickerWrapper domain={domain} data-testid="bnews-ticker-wrapper">
            <TickerList
              breakingNews={breakingNews}
              isAnimating={isAnimating}
              animationDuration={animationDuration}
              newsTickerRef={newsTickerRef}
              trackingTerm={trackingTerm}
            />
            {/* Duplicate ticker for continous scrolling */}
            <TickerList
              breakingNews={breakingNews}
              isAnimating={isAnimating}
              animationDuration={animationDuration}
              trackingTerm={trackingTerm}
            />
          </TickerWrapper>
        )}
      </Inner>
    </Wrapper>
  )
}
