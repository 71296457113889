import React from 'react'
import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'

const MOBILE_HEIGHT = 350
const DESKTOP_HEIGHT = 370

const Wrapper = styled.div`
  height: ${MOBILE_HEIGHT}px !important;

  mantis-ui-widget {
    height: 100%;
  }

  @media ${device.laptop} {
    height: ${DESKTOP_HEIGHT}px !important;

    mantis-ui-widget {
      height: 100%;
    }
  }
`

type DataConfigProps = {
  offset?: number
  theme?: string
  title: string
}

type MantisAdsProps = {
  index: number
  dataConfig: DataConfigProps
}

const MantisAds = ({ index, dataConfig }: MantisAdsProps) => {
  const wrapperId = `mantis-carousel-wrapper${index > 0 ? `-${index}` : ''}`
  const widgetId = `mantis-ui-widget${index > 0 ? `-${index}` : ''}`

  // Change the dataConfig object to a string so it can be passed to the widget
  const serialiseDataConfig = JSON.stringify(dataConfig)

  return (
    <Wrapper id={wrapperId} data-testid={wrapperId}>
      <mantis-ui-widget
        id={widgetId}
        data-testid={widgetId}
        data-config={serialiseDataConfig}
      />
    </Wrapper>
  )
}

export default MantisAds
