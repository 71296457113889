/*globals dataLayer JSGlobals */
import once from 'ramda/src/once'

const getIdFromUrl = (str) => {
  try {
    return str.split('?')[0].match(/\d+$/)[0] || null
  } catch (ex) {
    return null
  }
}

const fallbackNavigation = (event, currentTarget) => {
  setTimeout(() => {
    if (!event.defaultPrevented) {
      const href = currentTarget.getAttribute('href')
      const target = currentTarget.getAttribute('target')
      if (target === '_blank') {
        window.open(href, '_blank')
      } else {
        window.location.href = href
      }
    }
  }, 0)
}

export default () => {
  document
    .querySelectorAll('#article-next-menu a, #up-next-link')
    .forEach((el) => {
      el.addEventListener('click', (event) => {
        const { currentTarget } = event
        const matchedId = getIdFromUrl(currentTarget.getAttribute('href'))
        dataLayer.push({
          event: 'gaEvent',
          eventCategory: 'navigation',
          eventAction: 'next previous',
          eventLabel: currentTarget.getAttribute('data-vars-el'),
          titleClicked: currentTarget.getAttribute('title'),
          urlClicked: currentTarget.getAttribute('href'),
          articleIdClicked: matchedId,
          eventTimeout: 2000,
          eventCallback: once(() => {
            document.location = currentTarget.getAttribute('href')
          }),
        })
        fallbackNavigation(event, currentTarget)
      })
    })

  const sectionArticles = document.querySelectorAll('.article-item')
  sectionArticles.forEach((el, position) => {
    el.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', (event) => {
        const { currentTarget } = event
        const matchedId = getIdFromUrl(currentTarget.getAttribute('href'))
        dataLayer.push({
          event: 'gaEvent',
          eventCategory: 'navigation',
          eventAction: 'hero links',
          eventLabel: `${position}`,
          titleClicked: currentTarget.getAttribute('title'),
          urlClicked: currentTarget.getAttribute('href'),
          articleIdClicked: matchedId,
          centralIdClicked: null,
          eventCallback: once(() => {
            if (currentTarget.getAttribute('target') === '_blank') {
              window.open(currentTarget.getAttribute('href'))
              return
            }
            document.location = currentTarget.getAttribute('href')
          }),
          eventTimeout: 2000,
        })
        fallbackNavigation(event, currentTarget)
      })
    })
  })

  const liveBlogKeyEvents = document.querySelectorAll('#liveblog li')
  liveBlogKeyEvents.forEach((el, index) => {
    el.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', () => {
        dataLayer.push({
          event: 'live blog - key event',
          linkPosition: index + 1,
        })
      })
    })
  })

  document.querySelectorAll('ul.navbar a').forEach((link) => {
    link.addEventListener('click', function (event) {
      const { currentTarget } = event
      dataLayer.push({
        event: 'gaEvent',
        eventCategory: 'navigation',
        eventAction: 'header',
        eventLabel: currentTarget.innerHTML,
        eventTimeout: 2000,
        eventCallback: once(() => {
          document.location = currentTarget.getAttribute('href')
        }),
      })
      fallbackNavigation(event, currentTarget)
    })
  })

  document.querySelectorAll('#detailed-menu nav a').forEach((link) => {
    link.addEventListener('click', (event) => {
      const { currentTarget } = event
      const targetUrl = currentTarget.getAttribute('href')
      const isExternal = /^https?:\/\//.test(targetUrl)

      const data = {
        event: 'gaEvent',
        eventCategory: 'navigation',
        eventAction: 'sidebar',
        eventLabel: currentTarget.innerHTML,
        eventTimeout: 2000,
        eventCallback: once(() => {
          // After successfully tracking the event
          // - open external links in a new tab
          if (isExternal) {
            data.eventLabel = targetUrl
            data.eventAction = 'sidebar - outbound'
            window.open(targetUrl, '_blank')
          } else {
            // - internal links navigate to page
            document.location = targetUrl
          }
        }),
      }

      dataLayer.push(data)
      fallbackNavigation(event, currentTarget)
    })
  })

  document.querySelectorAll('.footer-menu a').forEach((link) => {
    link.addEventListener('click', function (event) {
      const { currentTarget } = event
      const targetUrl = currentTarget.getAttribute('href')
      const data = {
        event: 'gaEvent',
        eventCategory: 'navigation',
        eventAction: 'footer',
        eventLabel: currentTarget.innerHTML,
        eventCallback: once(() => {
          if (currentTarget.getAttribute('target') === '_blank') {
            window.open(currentTarget.getAttribute('href'))
            return
          }
          document.location = currentTarget.getAttribute('href')
        }),
        eventTimeout: 2000,
      }

      if (/^https?:\/\//.test(targetUrl)) {
        data.eventAction = 'footer - outbound'
      }

      dataLayer.push(data)
      fallbackNavigation(event, currentTarget)
    })
  })

  const sidebar = document.querySelector('.sidebar-most-popular')
  if (sidebar) {
    sidebar.addEventListener('click', (event) => {
      const anchor = event.target.closest('a')
      if (anchor) {
        const matchedId = getIdFromUrl(anchor.getAttribute('href'))
        const data = {
          event: 'gaEvent',
          eventCategory: 'navigation',
          eventAction: 'most popular',
          eventLabel: anchor.getAttribute('data-index'),
          articleIdClicked: matchedId,
          titleClicked: anchor.getAttribute('title'),
          urlClicked: anchor.getAttribute('href'),
          eventCallback: once(() => {
            document.location = anchor.getAttribute('href')
          }),
          eventTimeout: 2000,
        }
        dataLayer.push(data)
        fallbackNavigation(event, anchor)
      }
    })
  }

  document.querySelectorAll('.mostPopularInline a').forEach((anchor) => {
    anchor.addEventListener('click', (event) => {
      const { currentTarget } = event
      const matchedId = getIdFromUrl(anchor.getAttribute('href'))
      const data = {
        event: 'gaEvent',
        eventCategory: 'navigation',
        eventAction: 'most popular',
        eventLabel: anchor.getAttribute('data-index'),
        articleIdClicked: matchedId,
        titleClicked: anchor.getAttribute('title'),
        urlClicked: anchor.getAttribute('href'),
        eventCallback: once(() => {
          document.location = anchor.getAttribute('href')
        }),
        eventTimeout: 2000,
      }
      dataLayer.push(data)
      fallbackNavigation(event, currentTarget)
    })
  })

  document.querySelectorAll('#commentButtonDisqus').forEach((anchor) => {
    anchor.addEventListener('click', (event) => {
      const { currentTarget } = event
      const isTop = anchor.classList.contains('top')
      const isBottom = anchor.classList.contains('bottom')
      const data = {
        event: 'comment button',
        contentLocation: isTop ? 'top' : isBottom ? 'bottom' : null,
        eventCallback: once(() => {
          document.location = anchor.getAttribute('href')
        }),
        eventTimeout: 2000,
      }
      dataLayer.push(data)
      fallbackNavigation(event, currentTarget)
    })
  })

  const readMoreArticle = document.querySelector('.article-read-more')
  if (readMoreArticle) {
    readMoreArticle.addEventListener('click', (event) => {
      const anchor = event.target.closest('a')
      if (anchor) {
        const matchedId = getIdFromUrl(anchor.getAttribute('href'))
        const data = {
          event: 'gaEvent',
          eventCategory: 'navigation',
          eventAction: 'in article',
          eventLabel: 'read more',
          articleIdClicked: matchedId,
          titleClicked: anchor.getAttribute('title'),
          urlClicked: anchor.getAttribute('href'),
          eventTimeout: 2000,
          eventCallback: once(() => {
            document.location = anchor.getAttribute('href')
          }),
        }
        dataLayer.push(data)
        fallbackNavigation(event, anchor)
      }
    })
  }

  const markup = document.querySelectorAll('.markup a')
  if (markup.length > 0) {
    markup.forEach((element) => {
      element.addEventListener('click', (event) => {
        const anchor = event.currentTarget
        if (anchor) {
          const matchedId = getIdFromUrl(anchor.getAttribute('href'))
          if (matchedId) {
            const data = {
              event: 'gaEvent',
              eventCategory: 'navigation',
              eventAction: 'in article',
              eventLabel: 'plain links',
              articleIdClicked: matchedId,
              titleClicked: anchor.textContent,
              urlClicked: anchor.getAttribute('href'),
              eventCallback: once(() => {
                if (anchor.getAttribute('target') === '_blank') {
                  window.open(anchor.getAttribute('href'), '_blank')
                } else {
                  document.location = anchor.getAttribute('href')
                }
              }),
              eventTimeout: 2000,
            }
            dataLayer.push(data)
          } else {
            const data = {
              event: 'gaEvent',
              eventCategory: 'navigation',
              eventAction: 'in article - outbound',
              eventLabel: anchor.getAttribute('href'),
              eventCallback: once(() => {
                if (anchor.getAttribute('target') === '_blank') {
                  window.open(anchor.getAttribute('href'), '_blank')
                } else {
                  document.location = anchor.getAttribute('href')
                }
              }),
              eventTimeout: 2000,
            }
            dataLayer.push(data)
          }
          fallbackNavigation(event, anchor)
        }
      })
    })
  }

  // 'SocialShare' and 'SocialShareMobile'
  const socialShareLinks = [
    document.querySelectorAll('a.social-link'),
    document.querySelectorAll('button#social-share-mobile'),
  ]

  const addEventListenerToElements = (elements, eventType, dataMethod) => {
    elements.forEach((element) => {
      element.addEventListener(eventType, (event) => {
        const anchor = event.currentTarget
        if (anchor) {
          const data = {
            event: 'share',
            content_id: JSGlobals?.pageId,
            content_type: JSGlobals?.pageType,
            position: anchor.getAttribute('data-position'),
            ...(dataMethod && { method: anchor.getAttribute(dataMethod) }),
          }
          dataLayer.push(data)
        }
      })
    })
  }

  socialShareLinks.forEach((elements) => {
    if (elements.length) {
      addEventListenerToElements(
        elements,
        'click',
        elements === socialShareLinks[0] ? 'type' : null, // Do not include 'method' for 'SocialShareMobile'
      )
    }
  })

  const followFooterSocial = document.querySelectorAll('.footer-social a')
  if (followFooterSocial.length) {
    followFooterSocial.forEach((element) => {
      element.addEventListener('click', (event) => {
        const anchor = event.currentTarget
        if (anchor) {
          const data = {
            event: 'gaEvent',
            eventCategory: 'social',
            eventAction: anchor.getAttribute('data-type') + ' follow',
            eventLabel: 'footer',
            eventCallback: once(() => {
              document.location = anchor.getAttribute('href')
            }),
            eventTimeout: 2000,
          }
          dataLayer.push(data)
          fallbackNavigation(event, anchor)
        }
      })
    })
  }

  const hamburgerSocial = document.querySelectorAll('.detailed-menu-follow a')
  if (hamburgerSocial.length) {
    hamburgerSocial.forEach((element) => {
      element.addEventListener('click', (event) => {
        const anchor = event.currentTarget
        if (anchor) {
          const data = {
            event: 'gaEvent',
            eventCategory: 'social',
            eventAction: anchor.getAttribute('data-type') + ' follow',
            eventLabel: 'sidebar',
            eventTimeout: 2000,
            eventCallback: once(() => {
              // preventDefault() below prevents target attributes.
              // window.open() is added here to open external links
              // in a new tab after the analytics event is tracked
              window.open(anchor.getAttribute('href'), '_blank')
            }),
          }
          dataLayer.push(data)
          fallbackNavigation(event, anchor)
        }
      })
    })
  }

  const topics = document.querySelectorAll('a.topic')
  if (topics.length) {
    topics.forEach((element) => {
      element.addEventListener('click', (event) => {
        const anchor = event.currentTarget
        if (anchor) {
          const data = {
            event: 'select topic',
            element_position: anchor.getAttribute('data-position'),
            click_text: anchor.getAttribute('data-vars-name').toLowerCase(),
            click_url: anchor.getAttribute('href'),
            content_type: 'topic',
            selection_name: 'topic tags',
            position: 'in article - bottom',
            eventTimeout: 2000,
            eventCallback: once(() => {
              document.location = anchor.getAttribute('href')
            }),
          }
          dataLayer.push(data)
          fallbackNavigation(event, anchor)
        }
      })
    })
  }

  const newsletterSection = document.querySelectorAll('.newsletter-section')
  if (newsletterSection.length) {
    newsletterSection.forEach((element) => {
      element.addEventListener('click', (event) => {
        const anchor = event.currentTarget
        if (anchor) {
          const data = {
            event: 'gaEvent',
            eventCategory: 'newsletter',
            eventAction: 'click',
            eventLabel:
              document.location.pathname === '/' ? 'homepage' : 'category',
            eventTimeout: 2000,
            eventCallback: once(() => {
              document.location = anchor.getAttribute('href')
            }),
          }
          dataLayer.push(data)
          fallbackNavigation(event, anchor)
        }
      })
    })
  }

  const buyNowButton = document.querySelectorAll('.article-buy-now')
  if (buyNowButton.length) {
    buyNowButton.forEach((element) => {
      element.addEventListener('click', (event) => {
        const anchor = event.currentTarget
        if (anchor) {
          const data = {
            event: 'gaEvent',
            eventCategory: 'navigation',
            eventAction: 'affiliate buy button',
            eventLabel: anchor.getAttribute('href'),
            eventValue: undefined,
            eventNI: 0,
            eventTimeout: 2000,
            eventCallback: once(() => {
              window.open(anchor.getAttribute('href'), '_blank')
            }),
          }
          dataLayer.push(data)
          fallbackNavigation(event, anchor)
        }
      })
    })
  }

  const authorSubscribe = document.querySelectorAll('.subscribe-author')
  if (authorSubscribe.length) {
    authorSubscribe.forEach((element) => {
      element.addEventListener('click', (event) => {
        const anchor = event.currentTarget
        if (anchor) {
          const href = anchor.getAttribute('href')
          const data = {
            event: 'gaEvent',
            eventCategory: 'navigation',
            eventAction: 'author bio - subscribe',
            eventLabel: href,
            eventTimeout: 2000,
            eventCallback: once(() => {
              document.location = href
            }),
          }
          dataLayer.push(data)
          fallbackNavigation(event, anchor)
        }
      })
    })
  }

  const watchShotsButton = document.querySelector('.nw-tv')

  if (watchShotsButton) {
    watchShotsButton.addEventListener('click', (event) => {
      const anchor = event.currentTarget
      if (anchor) {
        const data = {
          event: 'select content',
          clickURL: anchor.getAttribute('href'),
          contentLocation: 'header',
          contentType: 'shots! tv',
          clickText: anchor.getAttribute('alt'),
          eventTimeout: 2000,
          eventCallback: once(() => {
            window.open(anchor.getAttribute('href'), '_blank')
          }),
        }
        dataLayer.push(data)
        fallbackNavigation(event, anchor)
      }
    })
  }

  const shotsTvBannerButton = document.querySelector('#shots-tv-banner')

  if (shotsTvBannerButton) {
    shotsTvBannerButton.addEventListener('click', (event) => {
      const anchor = event.currentTarget
      if (anchor) {
        const data = {
          event: 'select content',
          clickURL: anchor.getAttribute('href'),
          contentLocation: 'in article',
          contentType: 'shots! tv',
          clickText: anchor.textContent,
          eventTimeout: 2000,
          eventCallback: once(() => {
            window.open(anchor.getAttribute('href'), '_blank')
          }),
        }
        dataLayer.push(data)
        fallbackNavigation(event, anchor)
      }
    })
  }

  const authorSocialLinks = document.querySelectorAll(
    '[data-author-social-link]',
  )
  if (authorSocialLinks.length) {
    authorSocialLinks.forEach((element) => {
      element.addEventListener('click', (event) => {
        const anchor = event.currentTarget
        const type = anchor.getAttribute('data-author-social-link')
        if (anchor) {
          const href = anchor.getAttribute('href')
          const data = {
            event: 'gaEvent',
            eventCategory: 'navigation',
            eventAction: `author bio - ${type}`,
            eventLabel: type === 'email' ? type : href,
            eventTimeout: 2000,
            eventCallback: once(() => {
              window.open(anchor.getAttribute('href'), '_blank')
            }),
          }
          dataLayer.push(data)
          fallbackNavigation(event, anchor)
        }
      })
    })
  }

  document.addEventListener('custom:newsletterformsubmitted', function (e) {
    try {
      const detail = JSON.parse(e.detail)
      const data = {
        event: 'gaEvent',
        eventCategory: 'newsletter',
        eventAction: 'success',
        eventLabel: detail.formId,
      }
      dataLayer.push(data)
    } catch (e) {
      console.error(e)
    }
  })

  const continueReadingBtn = document.querySelector('#continue-reading-btn')
  if (continueReadingBtn) {
    continueReadingBtn.addEventListener('click', () => {
      dataLayer.push({
        event: 'continue reading',
      })
    })
  }
}
