import styled from 'styled-components'

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fontFamily.openSans};
`

export const AffiliateMessage = () => {
  return (
    <Text data-testid="article-affiliate-msg">
      This article contains affiliate links. We may earn a small commission on
      items purchased through this article, but that does not affect our
      editorial judgement.
    </Text>
  )
}
